/**
 * 交易价格类型
 */
export enum TradePriceType {
  /**
   * 市价
   */
  MARKET_PRICE = 'MarketPrice',
  /**
   * 限价
   */
  LIMIT_PRICE = 'LimitPrice',
}
