import request, { METHOD, Config } from '@/utils/request';

export const API = {
  MERGE_LIST: '/position/merge',
  SPLIT_LIST: '/position',
  CLOSED_LIST: '/position/closed',
  OPEN_AVG: '/position/open/avg/{$0}',
  SET_TP_SL: '/position/profit',
};

export default class {
  /**
   * 合并持仓列表
   */
  static getMergeList(params?: {}) {
    return request(METHOD.GET, API.MERGE_LIST, params, { needLoading: false, needMessage: false });
  }

  /**
   * 分笔持仓列表
   */
  static getSplitList(params?: {}, config: Config = { needLoading: false, needMessage: false }) {
    return request(METHOD.GET, API.SPLIT_LIST, params, config);
  }

  /**
   * 获取历史持仓
   */
  static getClosedList(params?: {}) {
    return request(METHOD.GET, API.CLOSED_LIST, params, { needLoading: false });
  }

  /**
   * 获取开仓均价
   */
  static getOpenAvg(params?: Array<any>) {
    return request(METHOD.GET, API.OPEN_AVG, params, { needLoading: false });
  }

  /**
   * 设置止盈止损
   */
  static setTP_SL(params?: {}) {
    return request(METHOD.POST, API.SET_TP_SL, params);
  }
}
