import mitt from 'mitt';
export default mitt();

export enum MittEvent {
  /**
   * 展示修改密码弹窗
   */
  SHOW_CHANGE_PASSWORD = 'show_change_password',
  /**
   * 前往撤单页面
   */
  TO_CAN_CANCEL = 'to_can_cancel',
}
