import $ from 'jquery';
import type { Ref } from 'vue';
import moment from 'moment';
import { useAppStore } from '@/store/modules/app';
import { useStockStore } from '@/store/modules/stock';
import { Theme } from '@/enums/Theme';
import { DesensitizationType } from '@/enums/Desensitization';
import type { Wallet } from '@/types/user';
import type { Quotation } from '@/types/quotation';
import { SelectOption } from '@/types/select-option';
import Thousandth from '@/utils/Thousandth';

/**
 * 根据传入的主题为指定元素添加对应主题 class
 */
interface ThemeOptions {
  selector?: string;
  darkClass?: string;
  lightClass?: string;
}
const defaultThemeOptions: ThemeOptions = {
  selector: 'html',
  darkClass: Theme.DARK,
  lightClass: Theme.LIGHT,
};
export const useTheme = (theme: Theme, options: ThemeOptions = {}) => {
  const mergeOptions = { ...defaultThemeOptions, ...options };
  const el = document.querySelector(mergeOptions.selector as keyof HTMLElementTagNameMap);
  switch (theme) {
    case Theme.DARK:
      el?.classList.remove(mergeOptions.lightClass as string);
      el?.classList.add(mergeOptions.darkClass as string);
      break;
    case Theme.LIGHT:
      el?.classList.remove(mergeOptions.darkClass as string);
      el?.classList.add(mergeOptions.lightClass as string);
      break;
  }
};

/**
 * 计算目标元素相对于视窗的可用高度
 * 注意在 mount 之后使用
 * @param target
 * @param below
 */
/*export const useUsableHeight = (target: Ref, below: Array<Ref> = []) => {
  let belowHeight = 0;
  below.forEach((item) => {
    belowHeight += item.value.clientHeight;
  });
  return window.innerHeight - useRect(target).top - belowHeight;
};*/

/**
 * 脱敏
 * @param str 需要脱敏的字符串
 * @param type 脱敏类型
 */
export const useDesensitization = (
  str: string,
  type: DesensitizationType = DesensitizationType.PHONE
) => {
  if (!str) return '';
  switch (type) {
    case DesensitizationType.PHONE:
      return str.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
    case DesensitizationType.BANK_CARD:
      return str.replace(/(\d{4})\d*(\d{4})/, '$1 **** **** $2');
    case DesensitizationType.ID_CARD:
      return str.replace(/(\w{6})\w*(\w{4})/, '$1********$2');
  }
  return str;
};

/**
 * 格式化日期
 * @param date
 * @return string
 */
export const useFmtDate = (date = '') => (date ? moment(date).format('YYYY-MM-DD') : '');

/**
 * 格式化日期时间
 * @param datetime
 * @param compact
 * @return string
 */
export const useFmtDatetime = (datetime = '', compact = false) =>
  datetime ? moment(datetime).format(compact ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss') : '';

/**
 * 格式化时间
 * @param datetime
 * @return string
 */
export const useFmtTime = (datetime = '') => (datetime ? moment(datetime).format('HH:mm:ss') : '');

/**
 * 美化数字，保留n位小数，千分位分隔
 * @param num
 * @param precision 精度，小数位数
 * @return string
 */
export const usePrettifyNumber = (num: any, precision = 2) => {
  let str = Thousandth.numberFormat(num, precision);
  if (str.includes('.')) {
    const decimalLen = str.length - str.indexOf('.') - 1;
    if (decimalLen < precision) {
      for (let i = 0; i < precision - decimalLen; i++) {
        str += '0';
      }
    }
  } else {
    str += '.';
    for (let i = 0; i < precision; i++) {
      str += '0';
    }
  }
  return str;

  /* 存在缺陷，已改用其他方法
  let floatNum = parseFloat(num);
  floatNum = isNaN(floatNum) ? 0 : floatNum;
  let str = parseFloat(floatNum.toFixed(precision)).toLocaleString();
  */
};

/**
 * 简化数字展示，保留两位小数，单位：万、亿、万亿、亿亿
 * @param num
 * @return string
 */
export const useSimplifyNumber = (num: any) => {
  if (isNaN(num)) return '0';
  return num.toFixed(0).replace(/(\d{1,4})((\d{4})*)$/, (a: string, b: string, c: string) => {
    const t = ['', '万', '亿', '万亿', '亿亿'][c.length / 4];
    return t ? `${b}.${c.slice(0, 2)}${t}` : b;
  });
};

/**
 * 计算第一个数相对于第二个数的占比
 * @param num
 * @param total
 */
export const useToPercent = (num: number, total: number) => {
  if (isNaN(num) || isNaN(total)) return 0;
  if (total === 0) return 0;
  return Math.round((num / total) * 10000) / 100;
};

/**
 * 计算涨跌幅
 * @param currentPrice 现价
 * @param originalPrice 原价
 * @return string
 */
export const useChangeRange = (currentPrice: any, originalPrice: any) => {
  return usePrettifyNumber(useToPercent(currentPrice - originalPrice, originalPrice));
};

/**
 * 根据数值正负返回颜色
 * @param num
 * @return string
 */
export const useColorGet = (num = 0) => {
  if (num > 0) return 'red';
  if (num < 0) return 'green';
};

/**
 * 美化钱包展示数据
 * @param wallet
 * @return Wallet
 */
export const usePrettifyWallet = (wallet: Wallet) => {
  wallet.balanceStr = usePrettifyNumber(wallet.balance);
  wallet.assetStr = usePrettifyNumber(wallet.asset);
  wallet.availableBalanceStr = usePrettifyNumber(wallet.availableBalance);
  wallet.freezeBalanceStr = usePrettifyNumber(wallet.freezeBalance);
  wallet.totalBalanceStr = usePrettifyNumber(wallet.totalBalance);
  wallet.withdrawAmountStr = usePrettifyNumber(wallet.withdrawAmount);
  wallet.totalDebtStr = usePrettifyNumber(wallet.totalDebt);
  wallet.remainBalanceStr = usePrettifyNumber(wallet.remainBalance);
  if (wallet.positionSummary) {
    wallet.positionSummary.totalAmountStr = usePrettifyNumber(wallet.positionSummary.totalAmount);
    wallet.positionSummary.profitStr = usePrettifyNumber(wallet.positionSummary.profit);
    wallet.positionSummary.todayProfitStr = usePrettifyNumber(wallet.positionSummary.todayProfit);
    wallet.positionSummary.totalMarginStr = usePrettifyNumber(wallet.positionSummary.totalMargin);
    wallet.positionSummary.marginRate = (wallet.positionSummary.marginRate ?? 0) * 100;
    wallet.positionSummary.marginRateStr = usePrettifyNumber(wallet.positionSummary.marginRate);
    wallet.positionSummary.totalCostStr = usePrettifyNumber(wallet.positionSummary.totalCost);
    wallet.positionSummary.buyTodayProfitStr = usePrettifyNumber(
      wallet.positionSummary.buyTodayProfit
    );
    wallet.positionSummary.buyTotalCostStr = usePrettifyNumber(wallet.positionSummary.buyTotalCost);
    wallet.positionSummary.buyTotalAmountStr = usePrettifyNumber(
      wallet.positionSummary.buyTotalAmount
    );
    wallet.positionSummary.buyTotalVolStr = usePrettifyNumber(wallet.positionSummary.buyTotalVol);
    wallet.positionSummary.buyProfitStr = usePrettifyNumber(wallet.positionSummary.buyProfit);
    wallet.positionSummary.sellTodayProfitStr = usePrettifyNumber(
      wallet.positionSummary.sellTodayProfit
    );
    wallet.positionSummary.sellTotalCostStr = usePrettifyNumber(
      wallet.positionSummary.sellTotalCost
    );
    wallet.positionSummary.sellTotalAmountStr = usePrettifyNumber(
      wallet.positionSummary.sellTotalAmount
    );
    wallet.positionSummary.sellTotalVolStr = usePrettifyNumber(wallet.positionSummary.sellTotalVol);
    wallet.positionSummary.sellProfitStr = usePrettifyNumber(wallet.positionSummary.sellProfit);
  }
  return wallet;
};

/**
 * 美化行情展示数据
 * @param quotation
 * @return Quotation
 */
export const usePrettifyQuotation = (quotation: Quotation) => {
  const stockStore = useStockStore();
  const preClose = stockStore.getStock(quotation.code ?? '').preClose ?? 0;
  quotation.timeStr = useFmtTime(quotation.time);
  quotation.latestStr = usePrettifyNumber(quotation.latest);
  quotation.change = (quotation.latest ?? 0) - preClose;
  quotation.changeStr = usePrettifyNumber(quotation.change);
  quotation.changeRatio = useChangeRange(quotation.latest, preClose);
  quotation.up = quotation.change >= 0;
  quotation.highStr = usePrettifyNumber(quotation.high);
  quotation.lowStr = usePrettifyNumber(quotation.low);
  quotation.openStr = usePrettifyNumber(quotation.open);
  quotation.turnoverRatioStr = usePrettifyNumber(quotation.turnoverRatio);
  quotation.volRatioStr = usePrettifyNumber(quotation.volRatio);
  quotation.totalCapitalStr = useSimplifyNumber(quotation.totalCapital);
  quotation.mvStr = useSimplifyNumber(quotation.mv);
  quotation.amountStr = useSimplifyNumber(quotation.amount);
  quotation.peTtmStr = usePrettifyNumber(quotation.peTtm);
  return quotation;
};

/**
 * 判断当前时间是否是交易时间
 */
export const useIsTradeTime = () => {
  const appStore = useAppStore();
  const { isTradeDay } = useStockStore();
  if (!isTradeDay) return false;
  const start = appStore.getSystemTime().hour(8).minute(59).second(0);
  const end = appStore.getSystemTime().hour(16).minute(0).second(0);
  return appStore.getSystemTime().isBetween(start, end);
};

/**
 * 判断当前时间是否是集合竞价时间
 */
export const isCallAuctionTime = () => {
  const appStore = useAppStore();
  const { isTradeDay } = useStockStore();
  if (!isTradeDay) return false;
  const start = appStore.getSystemTime().hour(9).minute(14).second(0);
  const end = appStore.getSystemTime().hour(9).minute(26).second(0);
  return appStore.getSystemTime().isBetween(start, end);
};

export enum DeviceType {
  IOS = 'ios',
  ANDROID = 'android',
  WECHAT = 'wechat',
  PC = 'pc',
}

/**
 * 判断设备类型
 */
export const useDeviceType = () => {
  const deviceTypes = [];
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('micromessenger')) {
    deviceTypes.push(DeviceType.WECHAT);
  }
  if (userAgent.includes('android') || userAgent.includes('adr')) {
    deviceTypes.push(DeviceType.ANDROID);
  }
  if (/(iphone|ipad|ipod|ios)/i.test(userAgent)) {
    deviceTypes.push(DeviceType.IOS);
  }
  if (!deviceTypes.length) {
    deviceTypes.push(DeviceType.PC);
  }
  return deviceTypes;
};

/**
 * 判断基本数据类型是否为空
 * @param value
 */
export const useIsEmpty = (value: any) => value === '' || value === null || value === undefined;

/**
 * 为列表添加符合 Select 组件所需的 Option 属性
 * @param list
 * @param textKey
 * @param valueKey
 */
export const useToSelectOptions = (
  list: Array<any>,
  textKey: string,
  valueKey: string
): Array<SelectOption> =>
  list.map((item) => ({
    ...item,
    label: item[textKey],
    value: item[valueKey],
  }));

/**
 * 动态加载js文件
 * @param src
 */
export const useLoadJS = (src: string) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      resolve('ok');
    };
    script.onerror = function () {
      reject(new Error('Failed to load: ' + src));
    };
    document.body.appendChild(script);
  });

/**
 * 获取当前元素的可用高度，通过计算父元素减去兄弟元素的高度
 */
export const useUsableHeight = (target: Ref) => {
  const self = $(target.value);
  const parent = self.parent();
  const siblings = self.siblings();
  let usableHeight = parent.height() ?? 0;
  siblings.each((i, el) => {
    usableHeight -= $(el).outerHeight(true) ?? 0;
  });
  return usableHeight;
};
