import { defineStore } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';
import type { Stock } from '@/types/stock';
import type { LimitPrice } from '@/types/limit-price';
import StockApi from '@/api/StockApi';
import OrderApi from '@/api/OrderApi';
import QuotationApi from '@/api/QuotationApi';
import { useIsEmpty, usePrettifyNumber } from '@/utils/use';

interface StockState {
  /**
   * 所有股票
   */
  allStock: Array<Stock>;
  /**
   * 当前选择交易的股票代码
   */
  currentStockCode: string;
  /**
   * 当前是否是交易日
   */
  isTradeDay: boolean | undefined;
}

export const useStockStore = defineStore('stock', {
  state: (): StockState => ({
    allStock: [],
    currentStockCode: '',
    isTradeDay: undefined,
  }),
  persist: [
    {
      key: '__stock',
      storage: localStorage,
      paths: ['allStock'],
    },
    {
      key: '__stock',
      storage: sessionStorage,
      paths: ['currentStockCode', 'isTradeDay'],
    },
  ],
  getters: {},
  actions: {
    async getAllStock() {
      const appStore = useAppStore();
      const { token } = useUserStore();
      if (!token) return;
      let res: any = null;
      try {
        res = await StockApi.getAll([appStore.getSystemTime().format('YYYYMMDD')]);
      } catch {
        res = await StockApi.getAll([
          appStore.getSystemTime().subtract(1, 'day').format('YYYYMMDD'),
        ]);
      }
      const list: Array<{ [attr: string]: any }> = res ?? [];
      list.forEach((item) => {
        Object.keys(item).forEach((key) => {
          item[key[0].toLowerCase() + key.slice(1)] = item[key];
        });
      });
      this.allStock = list as Array<Stock>;
    },
    getStock(code: string) {
      return (this.allStock.find((item) => item.code === code) ?? {}) as Stock;
    },
    getCurrentStock() {
      return this.getStock(this.currentStockCode) as Stock;
    },
    async checkTradeDay() {
      const appStore = useAppStore();
      const { token } = useUserStore();
      if (!token) return;
      const { data: res } = await OrderApi.checkTradeDay({
        date: appStore.getSystemTime().format('YYYY-MM-DD'),
      });
      this.isTradeDay = res.data;
    },
    async getLimitPrice(stockId: number | undefined) {
      if (!stockId || useIsEmpty(stockId)) return {};

      const { data: res } = await QuotationApi.getLimitPrice([stockId]);
      const limitPrice: LimitPrice = res.data;
      if (limitPrice.upperLimit) {
        limitPrice.upperLimitStr = usePrettifyNumber(limitPrice.upperLimit);
      }
      if (limitPrice.downLimit) {
        limitPrice.downLimitStr = usePrettifyNumber(limitPrice.downLimit);
      }
      return limitPrice;
    },
  },
});
