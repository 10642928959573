export default class {
  /**
   * 数字转千分位
   * @param value
   * @param digit 小数位数
   * @param isDigit
   */
  static numberFormat(value: number | string, digit = 2, isDigit = true) {
    value = Number(String(value).replace(/,/g, ''));
    if (value < 0) {
      value = value * -1;
      if (!value || value == 0) {
        return '0.00';
      }
      const str = String(this.toNumberStr(value)).split('.');
      const intPartFormat = str[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      if (isDigit) {
        if (str.length > 1 && digit !== 0) {
          if (str[1].length === 1) {
            str[1] = str[1] + '0';
          }
          return '-' + intPartFormat + '.' + str[1].slice(0, digit);
        }
        if (digit === 0) {
          return '-' + intPartFormat;
        }
        return '-' + intPartFormat + '.00';
      } else {
        if (str.length > 1) {
          return '-' + intPartFormat + '.' + str[1];
        } else {
          return '-' + intPartFormat;
        }
      }
    } else {
      if (!value || value == 0) {
        return '0.00';
      }
      const str = String(this.toNumberStr(value)).split('.');
      const intPartFormat = str[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      if (isDigit) {
        if (str.length > 1 && digit !== 0) {
          if (str[1].length === 1) {
            str[1] = str[1] + '0';
          }
          return intPartFormat + '.' + str[1].slice(0, digit);
        }
        if (digit === 0) {
          return intPartFormat;
        }
        return intPartFormat + '.00';
      } else {
        if (str.length > 1) {
          return intPartFormat + '.' + str[1];
        } else {
          return intPartFormat;
        }
      }
    }
  }
  static toNumberStr(num: number) {
    let result = String(Math.abs(num));
    if (result.indexOf('-') >= 0) {
      result = '0' + String(Number(result) + 1).slice(1);
    }
    return num < 0 ? '-' + result : result;
  }

  static toNumber(value: string) {
    return this.toNumberStr(Number(value.replace(/,/g, '')));
  }
}
