import { createRouter, createWebHashHistory } from 'vue-router';
import * as routeMap from './map';
import { useGuards } from '@/router/guards';

// 路由表
const routes = Object.values(routeMap).filter((route) => !(route.meta?.isSubRoute ?? false));

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由守卫
useGuards(router);

export default router;
