export enum HoldStockType {
  /**
   * 合并持仓
   */
  MERGE = 'merge',
  /**
   * 分笔持仓
   */
  SPLIT = 'split',
}
