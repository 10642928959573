import request, { METHOD } from '@/utils/request';

export const API = {
  LOGIN: '/user/login',
  REGISTER: '/user/reg',
  RESET_PWD: '/user/reset',
  MODIFY_PWD: '/user/password',
  USERINFO: '/user/info',
  WALLET: '/user/wallet',
  WALLET_LOGS: '/user/wallet/logs',
  SET_MULTIPLIER: '/user/multiplier',
  REAL_NAME: '/user/realname',
  SNAPSHOT: '/user/snapshot',
  INTEREST_RATES: '/user/interestRates',
};

export default class {
  /**
   * 登录
   */
  static login(params?: {}) {
    return request(METHOD.POST, API.LOGIN, params);
  }

  /**
   * 注册
   */
  static register(params?: {}) {
    return request(METHOD.POST, API.REGISTER, params);
  }

  /**
   * 重置密码
   */
  static resetPwd(params?: {}) {
    return request(METHOD.POST, API.RESET_PWD, params);
  }

  /**
   * 修改密码
   */
  static modifyPwd(params?: {}) {
    return request(METHOD.POST, API.MODIFY_PWD, params);
  }

  /**
   * 获取用户信息
   */
  static getUserinfo(params?: {}) {
    return request(METHOD.GET, API.USERINFO, params, { needLoading: false, needMessage: false });
  }

  /**
   * 获取钱包数据
   */
  static getWallet(params?: {}) {
    return request(METHOD.GET, API.WALLET, params, { needLoading: false, needMessage: false });
  }

  /**
   * 获取资金流水
   */
  static getWalletLogs(params?: {}) {
    return request(METHOD.GET, API.WALLET_LOGS, params, { needLoading: false });
  }

  /**
   * 设置资金倍数
   */
  static setMultiplier(params?: {}) {
    return request(METHOD.POST, API.SET_MULTIPLIER, params);
  }

  /**
   * 实名认证
   */
  static realName(params?: {}) {
    return request(METHOD.POST, API.REAL_NAME, params);
  }

  /**
   * 盈亏快照
   */
  static getSnapshot(params?: {}) {
    return request(METHOD.GET, API.SNAPSHOT, params, { needLoading: false });
  }

  /**
   * 获取利率信息
   */
  static getInterestRates(params?: {}) {
    return request(METHOD.GET, API.INTEREST_RATES, params, { needLoading: false });
  }
}
