export enum OrderStatusEnum {
  /**
   * 待下单
   */
  NEW_ORDER = 'NewOrder',
  /**
   * 待申报
   */
  WAIT_DECLARE = 'WaitDeclare',
  /**
   * 未报
   */
  UN_DECLARED = 'UnDeclared',
  /**
   * 已申报
   */
  DECLARED = 'Declared',
  /**
   * 部分成交
   */
  PARTIAL_DONE = 'PartialDone',
  /**
   * 已成交
   */
  DONE = 'Done',
  /**
   * 正在撤单
   */
  CANCELING = 'Canceling',
  /**
   * 已撤单
   */
  CANCELED = 'Canceled',
  /**
   * 废单
   */
  INVALID = 'Invalid',
  /**
   * 销仓
   */
  DESTROY = 'Destroy',
  /**
   * 部成部撤
   */
  PARTIAL_DONE_AND_CANCEL = 'PartialDoneAndCancel',
  /**
   * 部成待撤
   */
  PARTIAL_DONE_WAIT_CANCEL = 'PartialDoneWaitCancel',
}

export const OrderStatusName = {
  NewOrder: '待下单',
  WaitDeclare: '待申报',
  UnDeclared: '未报',
  Declared: '已申报',
  PartialDone: '部分成交',
  Done: '已成交',
  Canceling: '正在撤单',
  Canceled: '已撤单',
  Invalid: '废单',
  Destroy: '销仓',
  PartialDoneAndCancel: '部成部撤',
  PartialDoneWaitCancel: '部成待撤',
};

export const OrderStatusColor = {
  NewOrder: '#13c2c2',
  WaitDeclare: '#69c0ff',
  UnDeclared: '#d9d9d9',
  Declared: '#1890ff',
  PartialDone: '#ffd666',
  Done: '#409eff',
  Canceling: '#ffc069',
  Canceled: '#bfbfbf',
  Invalid: '#ff4d4f',
  Destroy: '#f5222d',
  PartialDoneAndCancel: '#fa8c16',
  PartialDoneWaitCancel: '#ff7a45',
};
