export enum TradeType2 {
  /**
   * 开仓
   */
  OPEN = 'Open',
  /**
   * 平仓
   */
  CLOSE = 'Close',
}

export const TradeType2Name = {
  Open: '开仓',
  Close: '平仓',
};
