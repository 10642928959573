import request, { METHOD } from '@/utils/request';
import type { Config } from '@/utils/request';

export const API = {
  OPEN: '/order/open',
  SELL_SHORT_ENQUIRY: '/order/open/sell/ask',
  SELL_SHORT_OPEN: '/order/open/sell/order',
  CLOSE: '/order/close',
  OPEN_STOCK_INFO: '/order/openInfo/{$0}',
  ENTRUST_RECORD_LIST: '/order',
  CANCEL_ORDER: '/order/{$0}',
  OPEN_SETTLE_LIST: '/order/settle/open',
  CLOSE_SETTLE_LIST: '/order/settle/close',
  CHECK_TRADE_DAY: '/order/isTradeDay',
  TRADE_DAYS: '/order/tradeDays',
  ENQUIRY_RECORD: '/order/ask',
  CANCEL_ENQUIRY: '/order/ask/{$0}',
};

export default class {
  /**
   * 开仓
   */
  static open(params?: {}) {
    return request(METHOD.POST, API.OPEN, params);
  }

  /**
   * 做空询价
   */
  static sellShortEnquiry(params?: {}) {
    return request(METHOD.POST, API.SELL_SHORT_ENQUIRY, params);
  }

  /**
   * 做空开仓
   */
  static sellShortOpen(params?: {}) {
    return request(METHOD.POST, API.SELL_SHORT_OPEN, params);
  }

  /**
   * 平仓
   */
  static close(params?: {}) {
    return request(METHOD.POST, API.CLOSE, params);
  }

  /**
   * 开仓信息
   */
  static getOpenStockInfo(params?: Array<any>, config: Config = {}) {
    return request(METHOD.GET, API.OPEN_STOCK_INFO, params, {
      needLoading: false,
      needMessage: false,
      ...config,
    });
  }

  /**
   * 委托记录
   */
  static getEntrustRecord(params?: {}) {
    return request(METHOD.GET, API.ENTRUST_RECORD_LIST, params, { needLoading: false });
  }

  /**
   * 撤单
   */
  static cancelOrder(params?: Array<any>, config: Config = {}) {
    return request(METHOD.DELETE, API.CANCEL_ORDER, params, config);
  }

  /**
   * 开仓结算记录
   */
  static getOpenSettleRecord(params?: {}) {
    return request(METHOD.GET, API.OPEN_SETTLE_LIST, params, { needLoading: false });
  }

  /**
   * 平仓结算记录
   */
  static getCloseSettleRecord(params?: {}) {
    return request(METHOD.GET, API.CLOSE_SETTLE_LIST, params, { needLoading: false });
  }

  /**
   * 判断是否是交易日
   */
  static checkTradeDay(params?: {}) {
    return request(METHOD.GET, API.CHECK_TRADE_DAY, params, { needLoading: false });
  }

  /**
   * 查询日期区间内的交易日
   */
  static getTradeDays(params?: {}) {
    return request(METHOD.GET, API.TRADE_DAYS, params, { needLoading: false });
  }

  /**
   * 询价记录
   */
  static getEnquiryRecord(params?: {}) {
    return request(METHOD.GET, API.ENQUIRY_RECORD, params);
  }

  /**
   * 取消询价
   */
  static cancelEnquiry(params?: Array<any>) {
    return request(METHOD.DELETE, API.CANCEL_ENQUIRY, params);
  }
}
