import { defineStore } from 'pinia';
import router from '@/router';
import UserApi from '@/api/UserApi';
import type { Userinfo, Wallet } from '@/types/user';
import { usePrettifyNumber, usePrettifyWallet } from '@/utils/use';
import { HomeRoute, LoginRoute } from '@/router/map';

interface UserState {
  token: string;
  /**
   * 用戶信息
   */
  userinfo: Userinfo;
  /**
   * 钱包数据
   */
  wallet: Wallet;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    token: '',
    userinfo: {},
    wallet: {},
  }),
  persist: [
    {
      key: '__user',
      storage: localStorage,
      paths: ['token'],
    },
    {
      key: '__user',
      storage: sessionStorage,
      paths: ['userinfo', 'wallet'],
    },
  ],
  actions: {
    async getWallet() {
      if (!this.token) return;
      const { data: res } = await UserApi.getWallet();
      this.wallet = usePrettifyWallet(res.data);
    },
    async getUserinfo() {
      if (!this.token) return;
      const { data: res } = await UserApi.getUserinfo();
      const userinfo: Userinfo = res.data;
      userinfo.isRealName = !!userinfo.idCard;
      userinfo.riskFileSigned = !!userinfo.riskFileFxqTaskNo;
      const perStockLimitRate = (userinfo.perStockLimitRate ?? 0) * 100;
      userinfo.perStockLimitRateStr = usePrettifyNumber(perStockLimitRate);
      const marginWithdrawRate = (userinfo.marginWithdrawRate ?? 0) * 100;
      userinfo.marginWithdrawRateStr = usePrettifyNumber(marginWithdrawRate);
      const forceCloseEarlyWarnRate = (userinfo.forceCloseEarlyWarnRate ?? 0) * 100;
      userinfo.forceCloseEarlyWarnRateStr = usePrettifyNumber(forceCloseEarlyWarnRate);
      const forceCloseWarnRate = (userinfo.forceCloseWarnRate ?? 0) * 100;
      userinfo.forceCloseWarnRateStr = usePrettifyNumber(forceCloseWarnRate);
      const forceCloseRate = (userinfo.forceCloseRate ?? 0) * 100;
      userinfo.forceCloseRateStr = usePrettifyNumber(forceCloseRate);
      this.userinfo = userinfo;
    },
    async login(params: {}) {
      const { data: res } = await UserApi.login(params);
      this.token = res.data;
      await this.getUserinfo();
    },
    logout(referrer = true) {
      this.$reset();
      let currentRoutePath = router.currentRoute.value.path;
      if (currentRoutePath === LoginRoute.path) {
        currentRoutePath = HomeRoute.path;
      }
      router.push({
        path: LoginRoute.path,
        query: { referrer: referrer ? currentRoutePath : '' },
        replace: true,
      });
    },
  },
});
