import { App } from 'vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import ElTableScrollLoad from '@/directive/el-table-scroll-load';

export default (app: App) => {
  app.use(ElementPlus);
  app.directive('el-table-scroll-load', ElTableScrollLoad);

  for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, component);
  }
};
