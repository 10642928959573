import { defineStore } from 'pinia';
import moment from 'moment';
import type { Moment } from 'moment';
import { Theme } from '@/enums/Theme';
import type { SystemConfigs } from '@/types/system-configs';
import CommonApi from '@/api/CommonApi';

interface AppState {
  /**
   * 主题，默认暗色模式
   */
  theme: Theme;
  /**
   * 系统时间
   */
  systemTime: Moment;
  /**
   * 搜索记录
   */
  searchHistory: Array<string>;
  /**
   * 系统配置
   */
  systemConfigs: SystemConfigs;
  /**
   * Storage 保存时间
   */
  storageDate: string;
  /**
   * 是否为模拟盘
   */
  isSimulation: boolean;
  /**
   * 模拟盘地址
   */
  simulationUrl: string;
  /**
   * 实盘地址
   */
  firmUrl: string;
  /**
   * 是否提醒过实名认证
   */
  realNameAlerted: boolean;
  /**
   * 是否提醒过签署风险告知书
   */
  signRiskFileAlerted: boolean;
  /**
   * 是否提醒过公告
   */
  noticeAlerted: boolean;
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    theme: Theme.DARK,
    systemTime: moment(),
    searchHistory: [],
    systemConfigs: {},
    storageDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    isSimulation: false,
    simulationUrl: 'http://1.15.83.129:7000',
    firmUrl: '',
    realNameAlerted: false,
    signRiskFileAlerted: false,
    noticeAlerted: false,
  }),
  persist: [
    {
      key: '__app',
      storage: localStorage,
      paths: ['theme', 'storageDate', 'searchHistory'],
    },
    {
      key: '__app',
      storage: sessionStorage,
      paths: [
        'appLink',
        'systemConfigs',
        'realNameAlerted',
        'signRiskFileAlerted',
        'noticeAlerted',
      ],
    },
  ],
  getters: {
    getSystemTime: (state) => () => moment(state.systemTime.clone()),
  },
  actions: {
    async calibrationSystemTime() {
      const { data: res } = await CommonApi.getSystemTime();
      const time = res.data ?? new Date();
      this.systemTime = moment(time);
    },
    async getSystemConfigs() {
      const { data: res } = await CommonApi.getSystemConfigs();
      this.systemConfigs = res.data ?? {};
    },
    refreshStorageDate() {
      this.storageDate = this.getSystemTime().format('YYYY-MM-DD HH:mm:ss');
    },
  },
});
