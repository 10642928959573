import type { Router } from 'vue-router';
import _ from 'lodash';
import moment from 'moment';
import { useAppStore } from '@/store/modules/app';
import { useStockStore } from '@/store/modules/stock';
import { useUserStore } from '@/store/modules/user';
import { LoginRoute, RegisterRoute, ForgetRoute, HomeRoute /*DownloadRoute*/ } from '@/router/map';

// 路由白名单，允许未登录访问
const routeWhitelist = [LoginRoute, RegisterRoute, ForgetRoute /*DownloadRoute*/];
// 登录后不允许访问的路由
const loggedNotAllowRoutes = [LoginRoute, RegisterRoute, ForgetRoute];

// 加载公共数据
const loadCommonData = () => {
  const appStore = useAppStore();
  const stockStore = useStockStore();
  const userStore = useUserStore();

  // 如果 Storage 的记录日期不是当天就清空数据，重新记录
  if (
    !moment(appStore.storageDate).isSame(appStore.getSystemTime(), 'day') &&
    moment(appStore.storageDate).diff(appStore.getSystemTime().hour(8).minute(31).second(0)) < 0
  ) {
    appStore.refreshStorageDate();
    appStore.systemConfigs = {};
    stockStore.$reset();
    userStore.wallet = {};
    userStore.userinfo = {};
  }

  // 加载用户数据
  if (_.isEmpty(userStore.userinfo)) {
    userStore.getUserinfo();
  }

  // 加载钱包数据
  if (_.isEmpty(userStore.wallet)) {
    userStore.getWallet();
  }

  // 判断当日是否是交易日
  if (typeof stockStore.isTradeDay === 'undefined') {
    stockStore.checkTradeDay();
  }

  // 加载所有股票
  if (_.isEmpty(stockStore.allStock)) {
    stockStore.getAllStock();
  }

  // 加载系统配置
  if (_.isEmpty(appStore.systemConfigs)) {
    appStore.getSystemConfigs();
  }
};

export const useGuards = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    // 设置document标题
    document.title = to.meta.title ?? process.env.VUE_APP_SYSTEM_NAME;

    // 判断路由是否在白名单中
    const inWhitelist = !!routeWhitelist.find((route) => to.path === route.path);

    if (inWhitelist) {
      // 判断路由是否为登录后不允许访问的路由
      const notAllow = !!loggedNotAllowRoutes.find((route) => to.path === route.path);
      // 如果是登录后不允许访问的路由，判断是否登录，已登录则返回原路由或首页
      if (notAllow && userStore.token) {
        return next(from.path || HomeRoute.path);
      }
      next();
    } else {
      // 如果不在白名单，判断是否登录
      if (userStore.token) {
        // 每次路由变化都加载公共数据（如：钱包）
        loadCommonData();
        next();
      } else {
        // 未登录跳转到登录页
        next({
          path: LoginRoute.path,
          query: {
            referrer: to.path,
          },
        });
      }
    }
  });
};
