import request, { METHOD } from '@/utils/request';

export const API = {
  BANK_LIST: '/common/banks',
  AREA_LIST: '/common/area',
  SYSTEM_CONFIGS: '/common/configs',
  SYSTEM_CONFIG: '/common/config',
  SMS_CAPTCHA: '/common/code',
  BANNER_LIST: '/common/banner',
  ANNOUNCEMENT_LIST: '/common/announcements',
  SYSTEM_TIME: '/common/time',
};

export default class {
  /**
   * 获取银行列表
   */
  static getBankList(params?: {}) {
    return request(METHOD.GET, API.BANK_LIST, params, { needLoading: false });
  }
  /**
   * 获取省市区列表
   */
  static getAreaList(params?: {}) {
    return request(METHOD.GET, API.AREA_LIST, params, { needLoading: false });
  }
  /**
   * 获取所有系统配置
   */
  static getSystemConfigs(params?: {}) {
    return request(METHOD.GET, API.SYSTEM_CONFIGS, params, { needLoading: false });
  }

  /**
   * 获取指定系统配置
   */
  static getSystemConfig(params?: {}) {
    return request(METHOD.GET, API.SYSTEM_CONFIG, params, { needLoading: false });
  }

  /**
   * 发送短信验证码
   */
  static sendSMSCaptcha(params?: {}) {
    return request(METHOD.POST, API.SMS_CAPTCHA, params);
  }

  /**
   * 获取轮播图列表
   */
  static getBannerList(params?: {}) {
    return request(METHOD.GET, API.BANNER_LIST, params, { needLoading: false });
  }

  /**
   * 获取系统公告列表
   */
  static getAnnouncementList(params?: {}) {
    return request(METHOD.GET, API.ANNOUNCEMENT_LIST, params, { needLoading: false });
  }

  /**
   * 获取系统时间
   */
  static getSystemTime(params?: {}) {
    return request(METHOD.GET, API.SYSTEM_TIME, params, { needLoading: false });
  }
}
