import axios from 'axios';
import request, { METHOD } from '@/utils/request';

export const API = {
  ALL: '/uploads/stock/{$0}.js',
  SELL_SHORT_IDS: '/stock/sell',
  OPTIONAL_LIST: '/stock/fav',
  OPTIONAL_ADD: '/stock/fav/{$0}',
  OPTIONAL_REMOVE: '/stock/fav/{$0}',
  OPTIONAL_IS: '/stock/isFav/{$0}',
};

export default class {
  /**
   * 所有可开仓股票
   */
  static async getAll(params?: Array<any>) {
    let url = API.ALL;
    params?.forEach?.((item, index) => {
      url = url.replace(`{$${index}}`, item);
    });
    const { data } = await axios.get(url);
    return data;
  }

  /**
   * 所有可做空股票ID
   */
  static getSellShortIds(params?: {}) {
    return request(METHOD.GET, API.SELL_SHORT_IDS, params);
  }

  /**
   * 用户自选股票
   */
  static getOptionalList(params?: {}) {
    return request(METHOD.GET, API.OPTIONAL_LIST, params, {
      needLoading: false,
      needMessage: false,
    });
  }

  /**
   * 添加自选
   */
  static addOptional(params?: Array<any>) {
    return request(METHOD.POST, API.OPTIONAL_ADD, params, { needLoading: false });
  }

  /**
   * 刪除自选
   */
  static removeOptional(params?: Array<any>) {
    return request(METHOD.DELETE, API.OPTIONAL_REMOVE, params, { needLoading: false });
  }

  /**
   * 是否自选
   */
  static isOptional(params?: Array<any>) {
    return request(METHOD.GET, API.OPTIONAL_IS, params, { needLoading: false });
  }
}
