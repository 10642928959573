import axios, { AxiosRequestConfig } from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import type { LoadingInstance } from 'element-plus/lib/components/loading/src/loading';
import { useUserStore } from '@/store/modules/user';
import { API as UserAPI } from '@/api/UserApi';
import { API as CommonAPI } from '@/api/CommonApi';

export enum METHOD {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  POST_FORM = 'postForm',
  DELETE = 'delete',
}

export interface Config extends AxiosRequestConfig {
  /**
   * 是否需要加载中蒙板
   */
  needLoading?: boolean;
  /**
   * 请求错误时是否展示错误信息
   */
  needMessage?: boolean;
  /**
   * 是否在拦截器中排除此请求
   */
  interceptExclude?: boolean;
}

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_PATH,
  timeout: 10000,
});

/**
 * @param message
 */
const reqLoading = (message = '加载中...') =>
  ElLoading.service({
    text: message,
  });

let loading: LoadingInstance;
request.interceptors.request.use((config: any) => {
  const reqWhitelist = [
    UserAPI.LOGIN,
    UserAPI.REGISTER,
    UserAPI.RESET_PWD,
    CommonAPI.SMS_CAPTCHA,
    CommonAPI.SYSTEM_CONFIG,
    CommonAPI.SYSTEM_TIME,
  ];
  const userStore = useUserStore();

  if (!reqWhitelist.includes(config.url)) {
    const token = userStore.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      ElMessage.error('请重新登录');
      userStore.logout();
      return Promise.reject();
    }
  }
  if (typeof config.needLoading === 'boolean') {
    if (config.needLoading) {
      loading = reqLoading(config.loadingMsg);
    }
  } else {
    loading = reqLoading(config.loadingMsg);
  }
  return config;
});

request.interceptors.response.use(
  (res) => {
    const config: Config = res.config;
    loading?.close?.();

    if (config.interceptExclude) return res;
    switch (res?.data?.code) {
      case 0:
        return res;
      default:
        if (typeof config.needMessage === 'boolean') {
          if (config.needMessage) {
            ElMessage.error(res?.data?.msg ?? '请求失败');
          }
        } else {
          ElMessage.error(res?.data?.msg ?? '请求失败');
        }
        return Promise.reject(res);
    }
  },

  (err) => {
    loading?.close?.();
    const userStore = useUserStore();
    const code = err?.response?.status;

    switch (code) {
      case 401:
        ElMessage.error({ message: '请重新登录', grouping: true });
        userStore.logout();
        break;
    }

    return Promise.reject(err);
  }
);

export default (method: METHOD, url: string, params: {} | [] = {}, config: Config = {}) => {
  // 适配动态路由的接口
  if (Array.isArray(params)) {
    params.forEach((item, index) => {
      url = url.replace(`{$${index}}`, item);
    });
    params = {};
  }

  switch (method) {
    case METHOD.GET:
      return request.get(url, { params, ...config });
    case METHOD.PUT:
      return request.put(url, params, config);
    case METHOD.POST:
      return request.post(url, params, config);
    case METHOD.POST_FORM:
      return request.postForm(url, params, config);
    case METHOD.DELETE:
      return request.delete(url, { params, ...config });
    default:
      return request.get(url, { params, ...config });
  }
};
