export enum EntrustRecordType {
  /**
   * 今日
   */
  TODAY = 'today',
  /**
   * 历史
   */
  PAST = 'past',
  /**
   * 可撤
   */
  CAN_CANCEL = 'canCancel',
  /**
   * 已成交
   */
  DEAL_ONLY = 'dealOnly',
  /**
   * 已清仓委托
   */
  FROM_LIQUIDATED = 'fromLiquidated',
}
