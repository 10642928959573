import request, { METHOD } from '@/utils/request';

export const API = {
  SIGN_URL: '/fxq/sign',
};

export default class {
  /**
   * 获取签署链接
   */
  static getSignUrl(params?: {}) {
    return request(METHOD.GET, API.SIGN_URL, params);
  }
}
