import request, { Config, METHOD } from '@/utils/request';

export const API = {
  GET: '/quote/tick/{$0}',
  HIGH_FREQUENCY: '/quote/highFrequency',
  HISTORY_QUOTATION: '/quote/history_quotation/{$0}',
  INDEX_LIST: '/quote/tick/index',
  LIMIT_PRICE: '/quote/limits/{$0}',
};

export default class {
  /**
   * 实时行情
   */
  static get(params?: Array<any>) {
    return request(METHOD.GET, API.GET, params, { needLoading: false, needMessage: false });
  }

  /**
   * 高频序列
   */
  static getHighFrequency(params?: {}) {
    return request(METHOD.GET, API.HIGH_FREQUENCY, params, { needLoading: false });
  }

  /**
   * 历史行情
   */
  static getHistoryQuotation(params?: Array<any>, config: Config = {}) {
    return request(METHOD.GET, API.HISTORY_QUOTATION, params, { needLoading: false, ...config });
  }

  /**
   * 指数行情
   */
  static getIndexList(params?: {}) {
    return request(METHOD.GET, API.INDEX_LIST, params, { needLoading: false });
  }

  /**
   * 历史行情
   */
  static getLimitPrice(params?: Array<any>) {
    return request(METHOD.GET, API.LIMIT_PRICE, params, { needLoading: false });
  }
}
