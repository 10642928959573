import _ from 'lodash';
import router from '@/router';
import { ElMessageBox, ElNotification } from 'element-plus';
import { OpenAccountRoute, SetFundMultipleRoute } from '@/router/map';
import { useAppStore } from '@/store/modules/app';
import { useUserStore } from '@/store/modules/user';
import SignApi from '@/api/SignApi';

/**
 * 实名认证工具
 */

/**
 * 检查是否完成实名认证
 * @param back 是否返回上级路由
 * @param force 是否强制提醒
 */
export const checkRealName = (back: boolean, force: boolean) => {
  const appStore = useAppStore();
  const { userinfo } = useUserStore();
  if (!userinfo.isRealName && (!appStore.realNameAlerted || force)) {
    appStore.realNameAlerted = true;
    ElMessageBox.confirm('请完成实名认证', '提醒', { confirmButtonText: '实名认证' })
      .then(() => {
        router.push(OpenAccountRoute.path);
      })
      .catch(() => {
        if (back) router.back();
      });
  }
};

/**
 * 检查是否签署风险告知书
 * @param back 是否返回上级路由
 * @param force 是否强制提醒
 * @param onlyCheckRealName 是否只验证是否实名
 */
export const checkSignRiskFile = async ({
  back = true,
  force = true,
  onlyCheckRealName = false,
} = {}) => {
  const appStore = useAppStore();
  if (appStore.isSimulation) return;
  const { userinfo } = useUserStore();
  if (!userinfo.isRealName) return checkRealName(back, force);
  if (!userinfo.riskFileSigned && (!appStore.signRiskFileAlerted || force) && !onlyCheckRealName) {
    appStore.signRiskFileAlerted = true;
    const { wallet } = useUserStore();
    if (wallet.multiplier === 0) {
      ElMessageBox.confirm('请先设置资金倍数', '提醒', { confirmButtonText: '去设置' })
        .then(() => {
          router.push(SetFundMultipleRoute.path);
        })
        .catch(() => {
          if (back) router.back();
        });
    } else {
      ElMessageBox.confirm('请签署风险告知书', '提醒', { confirmButtonText: '前往签署' })
        .then(() => {
          toSignRiskFile();
        })
        .catch(() => {
          if (back) router.back();
        });
    }
  }
};

/**
 * 前往签署风险告知书
 */
export const toSignRiskFile = async () => {
  const { userinfo } = useUserStore();
  if (userinfo.riskFileSigned) return;
  const { href } = window.location;
  const { data: res } = await SignApi.getSignUrl({ redirectUrl: href });
  const url = res?.data?.pcUrl ?? '';
  window.open(url, '_self');
};

/**
 * 判断是否从签署风险告知书返回
 */
export const checkFromSignRiskFile = () => {
  const userStore = useUserStore();
  const query = router.currentRoute.value.query;
  const code = query.code ? String(query.code) : null;
  const success = query.success ? String(query.success) : null;
  if (code && success) {
    const newQuery = _.omit(query, ['code', 'success']);
    router.replace({ query: newQuery });
    const notification = ElNotification({
      title: '提醒',
      message: '签署完成，正在查询签署结果',
      type: 'success',
      showClose: false,
      duration: 0,
    });
    setTimeout(async () => {
      await userStore.getUserinfo();
      notification.close();
      if (userStore.userinfo.riskFileSigned) {
        ElNotification({
          title: '提醒',
          message: '风险告知书签署成功',
          type: 'success',
        });
      } else {
        await ElMessageBox.confirm('风险告知书签署失败，请重新签署', '提醒', {
          confirmButtonText: '重新签署',
        });
        await toSignRiskFile();
      }
    }, 3000);
  }
};
