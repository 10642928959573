/**
 * 询价状态
 */
export enum EnquiryStatus {
  /**
   * 待询价
   */
  WAITING = 'Waiting',
  /**
   * 询价中
   */
  ASKING = 'Asking',
  /**
   * 已报价
   */
  ASKED = 'Asked',
  /**
   * 待下单
   */
  WAIT_ORDER = 'WaitOrder',
  /**
   * 已下单
   */
  ORDERED = 'Ordered',
  /**
   * 已完成
   */
  Done = 'Done',
}

export const EnquiryStatusName = {
  [EnquiryStatus.WAITING]: '待询价',
  [EnquiryStatus.ASKING]: '询价中',
  [EnquiryStatus.ASKED]: '已报价',
  [EnquiryStatus.WAIT_ORDER]: '待下单',
  [EnquiryStatus.ORDERED]: '已下单',
  [EnquiryStatus.Done]: '已完成',
};

export const EnquiryStatusColor = {
  [EnquiryStatus.WAITING]: '#13c2c2',
  [EnquiryStatus.ASKING]: '#69c0ff',
  [EnquiryStatus.ASKED]: '#1890ff',
  [EnquiryStatus.WAIT_ORDER]: '#ffd666',
  [EnquiryStatus.ORDERED]: '#95d475',
  [EnquiryStatus.Done]: '#55ff00',
};
