import { nextTick } from 'vue';
import type { ObjectDirective } from 'vue';
import { useElementSize, useInfiniteScroll, useIntervalFn } from '@vueuse/core';

const DISABLED_ATTR = 'scroll-load-disabled';
const DISTANCE = 200;
let checkViewHeightPause: Function;
const ElTableScrollLoad: ObjectDirective = {
  async mounted(el, binding) {
    const tableScrollWrap = el.querySelector('.el-scrollbar__wrap') as HTMLElement;
    const tableScrollView = tableScrollWrap.querySelector('.el-scrollbar__view') as HTMLElement;

    await nextTick();

    const { height: viewHeight } = useElementSize(tableScrollView);
    checkViewHeightPause = useIntervalFn(
      () => {
        if (viewHeight.value < tableScrollWrap.clientHeight + DISTANCE) {
          const disabled = el.getAttribute(DISABLED_ATTR);
          if (disabled === 'true') return;
          binding.value?.();
        } else {
          checkViewHeightPause();
        }
      },
      500,
      { immediateCallback: true }
    ).pause;

    useInfiniteScroll(
      tableScrollWrap,
      () => {
        const disabled = el.getAttribute(DISABLED_ATTR);
        if (disabled === 'true') return;
        binding.value?.();
      },
      { distance: DISTANCE }
    );
  },
  beforeUnmount() {
    checkViewHeightPause();
  },
};

export default ElTableScrollLoad;
