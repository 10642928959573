import { RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '@/types/route-meta';

export const LoginRoute: RouteRecordRaw = {
  name: 'login',
  path: '/login',
  component: () => import('@/views/auth/login/index.vue'),
  meta: <RouteMeta>{
    title: '登录',
    useHeader: false,
  },
};

export const RegisterRoute: RouteRecordRaw = {
  name: 'register',
  path: '/register',
  component: () => import('@/views/auth/register/index.vue'),
  meta: <RouteMeta>{
    title: '注册',
    useHeader: false,
  },
};

export const ForgetRoute: RouteRecordRaw = {
  name: 'forget',
  path: '/forget',
  component: () => import('@/views/auth/forget/index.vue'),
  meta: <RouteMeta>{
    title: '找回密码',
    useHeader: false,
  },
};

export const OptionalStockRoute: RouteRecordRaw = {
  name: 'optional-stock',
  path: '/optional-stock',
  component: () => import('@/views/optional-stock/index.vue'),
  meta: <RouteMeta>{
    keepAlive: true,
    title: '自选',
    useHeader: true,
    useQuotation: true,
    useTrade: true,
  },
};

export const IndividualStockRoute: RouteRecordRaw = {
  name: 'individual-stock',
  path: '/individual-stock',
  component: () => import('@/views/individual-stock/index.vue'),
  meta: <RouteMeta>{
    keepAlive: true,
    title: '个股',
    useHeader: true,
    useQuotation: true,
    useTrade: true,
  },
};

export const HomeRoute: RouteRecordRaw = {
  name: 'home',
  path: '/',
  redirect: OptionalStockRoute,
};

const PersonalCenterRoutePath = '/personal-center';
export const SetFundMultipleRoute: RouteRecordRaw = {
  name: 'set-fund-multiple',
  path: '/set-fund-multiple',
  component: () => import('@/views/personal-center/set-fund-multiple/index.vue'),
  meta: <RouteMeta>{
    title: '设置资金倍数',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const AssetsRoute: RouteRecordRaw = {
  name: 'assets',
  path: '/assets',
  component: () => import('@/views/personal-center/assets/index.vue'),
  meta: <RouteMeta>{
    title: '资产',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const CashFlowRoute: RouteRecordRaw = {
  name: 'cash-flow',
  path: '/cash-flow',
  component: () => import('@/views/personal-center/cash-flow/index.vue'),
  meta: <RouteMeta>{
    title: '资金流水',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const OpenAccountRoute: RouteRecordRaw = {
  name: 'open-account',
  path: '/open-account',
  component: () => import('@/views/personal-center/open-account/index.vue'),
  meta: <RouteMeta>{
    title: '实名开户',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const BankCardListRoute: RouteRecordRaw = {
  name: 'bank-card-list',
  path: '/bank-card-list',
  component: () => import('@/views/personal-center/bank-card/index.vue'),
  meta: <RouteMeta>{
    title: '绑定银行卡',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const UserManualRoute: RouteRecordRaw = {
  name: 'user-manual',
  path: '/user-manual',
  component: () => import('@/views/personal-center/user-manual/index.vue'),
  meta: <RouteMeta>{
    title: '使用手册',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const CustomerServiceRoute: RouteRecordRaw = {
  name: 'customer-service',
  path: '/customer-service',
  component: () => import('@/views/personal-center/customer-service/index.vue'),
  meta: <RouteMeta>{
    title: '客服热线',
    isSubRoute: true,
    parentPath: PersonalCenterRoutePath,
  },
};

export const PersonalCenterRoute: RouteRecordRaw = {
  name: 'personal-center',
  path: PersonalCenterRoutePath,
  redirect: '/set-fund-multiple',
  component: () => import('@/views/personal-center/index.vue'),
  meta: <RouteMeta>{
    title: '个人中心',
    useHeader: true,
  },
  children: [
    SetFundMultipleRoute,
    AssetsRoute,
    CashFlowRoute,
    OpenAccountRoute,
    BankCardListRoute,
    UserManualRoute,
    CustomerServiceRoute,
  ],
};
